import {Typography} from "@mui/material";

export default function Banner() {
    return(
        <div style={{
            backgroundImage: "url(/img/banner.png)",
            backgroundSize: "cover",
            minHeight: "120px",
            padding: "20px 0 0 20px"
        }}>
            <div style={{
                maxWidth: "800px",
                margin: "0 auto",
            }}>
                <Typography variant="h4" gutterBottom>
                    焦虑,抑郁等级检测及AI咨询
                </Typography>
                <Typography variant="body2" gutterBottom>
                    焦虑和抑郁是常见的心理健康问题<br/>
                    但很多人可能并不清楚自己是否受到了它们的影响<br/>
                    这个问卷旨在帮助您更好地了解自己的心理健康状态<br/>
                </Typography>
            </div>
        </div>
    )
}
