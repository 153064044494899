// src/ChatGPTDialog.js
import React, { useEffect, useState } from "react";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import "./chatdialog.css";

var localCatch = [];
function ChatGPTDialog() {
  const [messages, setMessages] = useState(localCatch);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    localCatch = messages;
  }, [messages]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const updateCurrentMessage = async (delta) => {
    setMessages((a) => {
      console.log(delta);
      const newArray = [...a];
      const lastIndex = newArray.length - 1;
      let msg = newArray[lastIndex];
      msg.content += delta;
      newArray[lastIndex] = msg;
      return newArray;
    });
  };

  const handleSubmit = async () => {
    if (inputValue.trim() === "") return;
    setIsLoading(true);

    const userMessage = {
      role: "user",
      content: inputValue,
    };

    const request = [];
    request.push(userMessage);
    for (let i = messages.length - 1, c = 0; i >= 0 && c < 4; i--, c++) {
      request.push(messages[i]);
    }
    request.reverse();

    setMessages((messages) => [
      ...messages,
      userMessage,
      {
        role: "assistant",
        content: "",
      },
    ]);

    try {
      await fetchEventSource("/chatgpt/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: request,
        }),
        onmessage(ev) {
          const obj = JSON.parse(ev.data);
          if (!(obj.payload ?? "")) {
            if (obj.status !== 0 && obj.message && obj.message !== "") {
              updateCurrentMessage(obj.message);
            }
            console.log(ev.data);
          } else {
            updateCurrentMessage(obj.payload);
          }
        },
        onerror(err) {
          updateCurrentMessage(err.message);
          console.error("EventSource error:", err);
        },
      });
    } catch (error) {
      console.error("Error:", error);
    }

    setInputValue(""); // 清空输入框
    setIsLoading(false);
  };

  const styles = {
    user: {
      backgroundColor: "#EFEFEF",
      color: "#000",
      borderRadius: "10px 10px 10px 0", // 右侧圆角以表示用户消息方向
      textAlign: "right",
    },
    assistant: {
      backgroundColor: "#DFF0D8",
      color: "#155724",
      borderRadius: "10px 10px 0 10px", // 左侧圆角以表示AI消息方向
      textAlign: "left",
    },
  };

  return (
    <div>
      <div style={{ fontSize: "0.8em", color: "red", fontWeight: "bold" }}>
        本服务公益免费，但是每次回答，都会消耗平台费用，请按需使用!
        聊天内容务必严格遵守国家法律！
      </div>
      <div className="chat-dialog">
        <div className="messages">
          {messages.map((msg, index) => (
            <div key={index}>
              <div
                dangerouslySetInnerHTML={{
                  __html: msg.content.replace(/\n/g, "<br>"),
                }}
                key={index}
                style={styles[msg.role]}
              />
              <br />
            </div>
          ))}
        </div>
        <div className="input-container">
          <input
            maxLength={200}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            className="input"
            onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
          />
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className="button"
          >
            {isLoading ? "对话中..." : "  发 送 "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatGPTDialog;
