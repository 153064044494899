import {Fab} from "@mui/material";
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import {useEffect, useRef, useState} from "react";
import "./styles.css";
import {fetchEventSource} from "@microsoft/fetch-event-source";

function ChatbotButton({chatbotOpen, onclick}) {
    function getIcon() {
        return chatbotOpen ? <CloseIcon sx={{ mr: 1 }} /> : <ChatIcon sx={{ mr: 1 }} />
    }

    function getText() {
        return chatbotOpen ? "关闭" : "AI咨询"
    }

    return (
        <div className="chatbot-toggler">
            <Fab variant="extended" color="primary" onClick={onclick}>
                {getIcon()}
                {getText()}
            </Fab>
        </div>
    )
}

function ChatBotWindow({onclick}) {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [lastMessage, setLastMessage] = useState("");

    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    useEffect(scrollToBottom, [messages]);
    useEffect(scrollToBottom, [lastMessage]);

    useEffect(() => {
        setTimeout(() => {
            setMessages([
                {role: "system", content: "本服务公益免费，但是每次回答，都会消耗平台费用，请按需使用! 咨询内容务必严格遵守国家法律！"},
                {role: "system", content: "请问有什么需要咨询的吗？"},
            ])
        });
    }, []);
    
    const appendLastSseMessage = (msg) => {
        setLastMessage((prev) => {
            return prev + msg;
        });
    }

    const commitLastSseMessage = () => {
        setLastMessage((prevLastMessage) => {
            if (prevLastMessage !== "") {
                appendMessage("assistant", prevLastMessage);
            }
            return "";
        })

        setLoading(false);
    }

    const appendMessage = (role, content) => {
        setMessages((prevMessages) => {
            return [...prevMessages, {role: role, content: content}]
        })
    }

    const handleSubmit = (msg) => {
        const userMessage = {
            role: "user",
            content: inputValue
        }

        const lastMessages = [];
        lastMessages.push(userMessage);
        for (let i = messages.length - 1, c = 0; i >= 0 && c < 4; i--) {
            if (messages[i].role !== "assistant" && messages[i].role !== "user") {
                continue;
            }
            lastMessages.push(messages[i]);
            c++
        }
        lastMessages.reverse();
        console.log("Last messages: ", lastMessages);

        fetchEventSource("/chatgpt/chat", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                prompt: lastMessages,
            }),
            onmessage(ev) {
                const obj = JSON.parse(ev.data);
                if (!(obj.payload ?? "")) {
                    if (obj.status !== 0 && obj.message && obj.message !== "") {
                        appendLastSseMessage(obj.message);
                    }
                } else {
                    appendLastSseMessage(obj.payload);
                }
            },
            onerror(err) {
                appendMessage("system", "抱歉，请稍后再试！onerror:" + err.message);
            }
        }).then().catch(e => {
            appendMessage("system", "抱歉，请稍后再试！ exception:" + e.message);
        }).finally(() => {
            commitLastSseMessage();
        })
    }

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSendClick();
        }
    }

    const handleSendClick = () => {
        if (inputValue.trim() === "") {
            return;
        }

        if (isLoading) {
            return;
        }

        setLoading(true);
        setLastMessage("")

        appendMessage("user", inputValue)
        handleSubmit(inputValue);
        setInputValue("");
    }

    const getIncomingMessage = (idx, content) => {
        return (
            <div key={idx} className="incoming">
                <span>
                    <SmartToyIcon className="chatbot-incoming-bot"/>
                </span>
                <p>{content}</p>
            </div>
        )
    }

    const getOutgoingMessage = (idx, content) => {
        return (
            <div key={idx} className="outgoing">
                <p>{content}</p>
            </div>
        )
    }

    return (
        <div className="chatbot">
            <header>
                <div>AI咨询</div>
                <span>
                    <CloseIcon onClick={onclick}/>
                </span>
            </header>

            <div className="chatbox">
                {messages.map((msg, idx) => (
                    <div key={idx}>
                        {msg.role === "user"
                            ? getOutgoingMessage(idx, msg.content)
                            : getIncomingMessage(idx, msg.content)}
                    </div>
                ))}
                {isLoading && getIncomingMessage(messages.length, lastMessage)}
                <div ref={messagesEndRef}/>
            </div>

            <div className="chat-input">
                <input value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyDown}/>
                <span>
                    <SendIcon onClick={handleSendClick}/>
                </span>
            </div>
        </div>
    )
}

export default function Chatbot() {
    const [chatbotOpen, setChatbotOpen] = useState(false);
    const [sseSupported, setSseSupported] = useState(false);
    const [isMicromessenger, setIsMicromessenger] = useState(false);

    useEffect(() => {
        setSseSupported(!!window.EventSource);
    }, []);

    const handleChatbotClick = () => {
        if (!sseSupported) {
            alert("抱歉，您的浏览器不支持AI咨询功能。");
            return;
        }
        setChatbotOpen(!chatbotOpen);
    }

    return (
        <div>
            <ChatbotButton chatbotOpen={chatbotOpen} onclick={handleChatbotClick}/>
            {sseSupported && chatbotOpen && <ChatBotWindow onclick={handleChatbotClick}/>}
        </div>
    )
}
