import React from 'react';
import './Wechat.css';

const Wechat = () => {
  return (
    <div className="App-header">
        {/* <h5>添加微信</h5>
        <p>任何建议和问题，欢迎微信私聊</p> */}
        <div className="container">
            <img src="/wechat.jpeg" className="image" alt="placeholder" />
        </div>
    </div>
  );
};

export default Wechat;