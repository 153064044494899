import {useState} from "react";
import {Tab, Tabs} from "@mui/material";
import Questions from "./questions";

export default function QTabs() {
    const [tab, setTab] = useState("sas");

    const handleTab = (e, newValue) => {
        e.preventDefault();
        setTab(newValue);
    }

    return (
        <div style={{width: "100%", minHeight: "800px"}}>
            <Tabs value={tab} onChange={handleTab} centered>
                <Tab label="焦虑量表（SAS）" value="sas" />
                <Tab label="抑郁量表（SDS）" value="sds" />
            </Tabs>
            <div style={{maxWidth: "800px", margin: "0 auto"}}>
                <Questions questionType={tab}/>
            </div>
        </div>
    );
}

