// import logo from './logo.svg';
import './App.css';
import Tabs from './components/Tabs';
import Wechat from './components/Wechat';
import Banner from "./components_v2/banner";
import QTabs from "./components_v2/qtabs";
import {Divider} from "@mui/material";
import Chatbot from "./components_v2/chatbot";
import ChatGPTDialog from "./components/chatdialog";


function App() {
  return (
    <div className="App">
        <Banner />
        <QTabs />
        <Wechat />
        <Chatbot />
    </div>
  );
}

export default App;
