import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

function ResultDialog({open, handleClose, scoreText, errorMessage}) {
    let guideText = "";
    if (scoreText !== "") {
        guideText = "您可以点击屏幕右下方的AI咨询按钮，也扫描屏幕下方二维码添加微信。";
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>提交结果</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Typography gutterBottom>
                    {scoreText}
                    {errorMessage}
                </Typography>
                <Typography gutterBottom>
                    {guideText}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    确定
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default function Questions({questionType}) {
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState(new Map());
    const [scoreText, setScoreText] = useState("");
    const [answered, setAnswered] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setQuestions([]);
        setAnswers(new Map());

        async function fetchQuestions() {
            const response = await axios.get('/api/survey-questions/' + questionType);
            setQuestions(response.data);
            const initAnswers = new Map();
            response.data.forEach((question) => {
                initAnswers.set(question.id, -1);
            });
            setAnswers(initAnswers);
            setScoreText("");
            setErrorMessage("");
            setAnswered(false);
        }

        setTimeout(() => {
            fetchQuestions().then();
        }, 0);

        console.log("fetchQuestions");
    },[questionType]);

    const checkAllAnswered = () => {
        console.log(answers);
        setTimeout(() => {
            let allAnswered = true;
            answers.forEach((value, _) => {
                if (value < 0) {
                    allAnswered = false;
                }
            });
            if (answers.size <= 0) {
                allAnswered = false;
            }
            if (allAnswered) {
                console.log("All answered");
            }

            setAnswered(allAnswered);
        });
    }

    const handleOptionChange = (questionId, choice) => {
        setAnswers(new Map(answers.set(questionId, choice)));
        checkAllAnswered();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        setTimeout(async () => {
            try {
                const result = [];
                answers.forEach((value,key)=> {
                    result.push({
                        id: key,
                        choice: value
                    });
                });
                const score = await axios.post("/api/survey-results/" + questionType, result);
                setScoreText(score.data);
            } catch (error) {
                setErrorMessage("提交失败，请稍候重试或联系客服。");
            } finally {
                setOpen(true);
                setLoading(false);
            }
        }, 0);
    };

    const handleCloseResultDialog = () => {
        setOpen(false);
        setScoreText("");
        setErrorMessage("");
    }

    if (questions.length === 0) {
        return <div style={{maxWidth: "600px", height: "200px", margin: "auto", textAlign: "center"}}>
            <CircularProgress style={{margin: "40px"}}/>
            <div>问卷加载中...</div>
        </div>
    }

    const getQuestionsDivs = () => {
        return questions.map((question, idxQuestion) => (
            <div key={question.id}>
                <Typography variant="subtitle2" gutterBottom>
                    {question.question}
                </Typography>
                <div style={{
                    display: "grid",
                    width: "100%",
                    gridTemplateColumns: "1fr",
                    marginBottom: "15px"
                }}>
                    {question.options.map((option, idxOption) => (
                        <label key={idxOption} style={{fontSize: "0.9rem"}}>
                            <input
                                type="radio"
                                checked={answers.get(question.id) === idxOption}
                                name={`question${idxQuestion}`}
                                value={question.id}
                                onChange={() => handleOptionChange(question.id, idxOption)}
                            />
                            {option}
                        </label>
                    ))}
                </div>
            </div>
        ));
    }

    return (
        <div>
            <div style={{padding: "5px 20px", margin: "10px auto", maxWidth: "80%"}}>
                <Alert
                    severity="info">请根据您最近一周的实际感受，选择与您情况相符的选项。答案没有对错之分，只需凭第一印象回答即可。</Alert>
            </div>

            <div style={{margin: "10px auto", maxWidth: "80%"}}>
                {getQuestionsDivs()}
            </div>

            <div style={{textAlign: "center", margin: "20px 0"}}>
                <Button
                    disabled={!answered || loading}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{width: "80%", height: "50px"}}>
                    {loading && <CircularProgress size={20} style={{marginRight: "10px"}} />}
                    {!loading && <span>提交</span>}
                </Button>
            </div>

            <ResultDialog
                open={open}
                handleClose={handleCloseResultDialog}
                scoreText={scoreText}
                errorMessage={errorMessage} />

        </div>
    )
}
